import {
	Button,
	Errors,
	Field,
	FieldErrors,
	Heading,
	Input,
	Label,
	Option,
	Select,
	TextField,
	TextLink,
} from '@troon/ui';
import { createEffect, Match, Switch } from 'solid-js';
import { Formspree, formspreeAction } from '@troon/forms';
import { useSubmission } from '@solidjs/router';
import { firePixel } from '@troon/analytics';
import { Icon } from '@troon/icons';
import { getConfigValue } from '../../../modules/config';
import { useUser } from '../../../providers/user';

export function ContactForm() {
	const user = useUser();
	const submission = useSubmission(action);

	createEffect(() => {
		if (submission.result && !submission.error) {
			firePixel('lead');
		}
	});

	return (
		<div class="flex flex-col gap-4">
			<Heading as="h2" size="h3">
				Let us hear from you
			</Heading>
			<p>
				To speak with a member of our Business Development team, please call{' '}
				<TextLink href="tel:4804770505" class="text-brand-600">
					480.477.0505
				</TextLink>{' '}
				or complete the following form:
			</p>

			<p class="italic text-neutral-800">
				For Troon Card/Rewards questions, please{' '}
				<TextLink href="/about/contact" class="text-brand-600 underline">
					contact support
				</TextLink>
				.
			</p>

			<Switch>
				<Match when={submission.result}>
					<div class="container mx-auto flex max-w-xl flex-col items-center gap-6 text-center">
						<Icon name="circle-check-custom" class="text-8xl" />
						<Heading as="h1" size="h2">
							Message received!
						</Heading>
						<p class="text-lg">
							Thanks for contacting us! Our team is reviewing your request and we will get in touch with you shortly.
						</p>
					</div>
				</Match>
				<Match when>
					<Formspree action={action}>
						<div class="flex w-full flex-wrap gap-6">
							<TextField name="First name" required class="grow">
								<Label>First name</Label>
								<Input autocomplete="given-name" value={user()?.me.firstName} />
							</TextField>

							<TextField name="Last name" required class="grow">
								<Label>Last name</Label>
								<Input autocomplete="family-name" value={user()?.me.lastName} />
							</TextField>
						</div>

						<div class="flex w-full flex-wrap gap-6">
							<TextField name="email" required class="grow">
								<Label>Email address</Label>
								<Input autocomplete="email" inputMode="email" />
							</TextField>

							<TextField name="Phone number" required class="grow">
								<Label>Phone number</Label>
								<Input autocomplete="tel" inputMode="tel" />
							</TextField>
						</div>

						<Field name="Category of interest" required>
							<Label>Category of interest</Label>
							<Select>
								<Option disabled selected class="hidden" />
								<Option>Private Club</Option>
								<Option>Daily Fee/Resort</Option>
								<Option>Municipal</Option>
								<Option>Community Association</Option>
								<Option>Advisory Services</Option>
								<Option>Troon Partners Network</Option>
								<Option>Other</Option>
							</Select>
							<FieldErrors />
						</Field>

						<Field name="Your role/position" required>
							<Label>Your role/position</Label>
							<Select>
								<Option disabled selected class="hidden" />
								<Option>Owner</Option>
								<Option>Owner Representative</Option>
								<Option>Club Board Member</Option>
								<Option>General Manager</Option>
								<Option>Mayor</Option>
								<Option>City Manager</Option>
								<Option>Chief Financial Officer</Option>
								<Option>Parks and Recreation Director</Option>
								<Option>Other</Option>
							</Select>
							<FieldErrors />
						</Field>

						<TextField name="Message" required>
							<Label>Message</Label>
							<Input multiline />
						</TextField>

						<Errors />
						<Button type="submit">Submit</Button>
					</Formspree>
				</Match>
			</Switch>
		</div>
	);
}

const action = formspreeAction(getConfigValue('FORMSPREE_PROJECT_ID'), 'management-services');
